import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

interface IProps {
  stat: string;
}

export const BCMStat: React.FC<IProps> = ({ stat }) => {
  return (
    <div className="hsr-stat">
      {stat === 'power' && (
        <>
          <StaticImage
            src="../../../../images/blackclover/icons/stat_power.png"
            alt="power"
          />
          <span>Combat Power</span>
        </>
      )}
      {stat === 'atk' && (
        <>
          <StaticImage
            src="../../../../images/blackclover/icons/stat_atk.png"
            alt="ATK"
          />
          <span>ATK</span>
        </>
      )}
      {stat === 'matk' && (
        <>
          <StaticImage
            src="../../../../images/blackclover/icons/stat_matk.png"
            alt="M. ATK"
          />
          <span>M. ATK</span>
        </>
      )}
      {stat === 'def' && (
        <>
          <StaticImage
            src="../../../../images/blackclover/icons/stat_def.png"
            alt="DEF"
          />
          <span>DEF</span>
        </>
      )}
      {stat === 'hp' && (
        <>
          <StaticImage
            src="../../../../images/blackclover/icons/stat_hp.png"
            alt="HP"
          />
          <span>HP</span>
        </>
      )}
      {stat === 'acc' && (
        <>
          <StaticImage
            src="../../../../images/blackclover/icons/stat_acc.png"
            alt="ACC"
          />
          <span>ACC</span>
        </>
      )}
      {stat === 'dmgres' && (
        <>
          <StaticImage
            src="../../../../images/blackclover/icons/stat_dmgres.png"
            alt="DMG Res"
          />
          <span>DMG RES</span>
        </>
      )}
      {stat === 'crit' && (
        <>
          <StaticImage
            src="../../../../images/blackclover/icons/stat_crit.png"
            alt="CRIT Rate"
          />
          <span>CRIT Rate</span>
        </>
      )}
      {stat === 'critdmg' && (
        <>
          <StaticImage
            src="../../../../images/blackclover/icons/stat_critdmg.png"
            alt="CRIT DMG"
          />
          <span>CRIT DMG</span>
        </>
      )}
      {stat === 'critres' && (
        <>
          <StaticImage
            src="../../../../images/blackclover/icons/stat_critres.png"
            alt="CRIT RES"
          />
          <span>CRIT RES</span>
        </>
      )}
      {stat === 'speed' && (
        <>
          <StaticImage
            src="../../../../images/blackclover/icons/stat_speed.png"
            alt="Speed"
          />
          <span>Speed</span>
        </>
      )}
      {stat === 'pen' && (
        <>
          <StaticImage
            src="../../../../images/blackclover/icons/stat_pen.png"
            alt="Penetration"
          />
          <span>Penetration</span>
        </>
      )}
      {stat === 'end' && (
        <>
          <StaticImage
            src="../../../../images/blackclover/icons/stat_end.png"
            alt="Endurance"
          />
          <span>Endurance</span>
        </>
      )}
    </div>
  );
};
